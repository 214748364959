exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ccpa-js": () => import("./../../../src/pages/ccpa.js" /* webpackChunkName: "component---src-pages-ccpa-js" */),
  "component---src-templates-innovation-single-js": () => import("./../../../src/templates/innovation-single.js" /* webpackChunkName: "component---src-templates-innovation-single-js" */),
  "component---src-templates-job-single-js": () => import("./../../../src/templates/job-single.js" /* webpackChunkName: "component---src-templates-job-single-js" */),
  "component---src-templates-landing-page-single-js": () => import("./../../../src/templates/landing-page-single.js" /* webpackChunkName: "component---src-templates-landing-page-single-js" */),
  "component---src-templates-pages-about-js": () => import("./../../../src/templates/pages/about.js" /* webpackChunkName: "component---src-templates-pages-about-js" */),
  "component---src-templates-pages-adspecs-js": () => import("./../../../src/templates/pages/adspecs.js" /* webpackChunkName: "component---src-templates-pages-adspecs-js" */),
  "component---src-templates-pages-contact-us-js": () => import("./../../../src/templates/pages/contact-us.js" /* webpackChunkName: "component---src-templates-pages-contact-us-js" */),
  "component---src-templates-pages-index-js": () => import("./../../../src/templates/pages/index.js" /* webpackChunkName: "component---src-templates-pages-index-js" */),
  "component---src-templates-pages-innovation-js": () => import("./../../../src/templates/pages/innovation.js" /* webpackChunkName: "component---src-templates-pages-innovation-js" */),
  "component---src-templates-pages-jobs-js": () => import("./../../../src/templates/pages/jobs.js" /* webpackChunkName: "component---src-templates-pages-jobs-js" */),
  "component---src-templates-pages-our-advertisers-js": () => import("./../../../src/templates/pages/our-advertisers.js" /* webpackChunkName: "component---src-templates-pages-our-advertisers-js" */),
  "component---src-templates-pages-press-js": () => import("./../../../src/templates/pages/press.js" /* webpackChunkName: "component---src-templates-pages-press-js" */),
  "component---src-templates-pages-privacy-policy-js": () => import("./../../../src/templates/pages/privacy-policy.js" /* webpackChunkName: "component---src-templates-pages-privacy-policy-js" */),
  "component---src-templates-pages-products-js": () => import("./../../../src/templates/pages/products.js" /* webpackChunkName: "component---src-templates-pages-products-js" */),
  "component---src-templates-pages-publishers-js": () => import("./../../../src/templates/pages/publishers.js" /* webpackChunkName: "component---src-templates-pages-publishers-js" */),
  "component---src-templates-pages-terms-and-conditions-js": () => import("./../../../src/templates/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-templates-pages-terms-and-conditions-js" */),
  "component---src-templates-pages-videos-js": () => import("./../../../src/templates/pages/videos.js" /* webpackChunkName: "component---src-templates-pages-videos-js" */),
  "component---src-templates-pages-works-js": () => import("./../../../src/templates/pages/works.js" /* webpackChunkName: "component---src-templates-pages-works-js" */),
  "component---src-templates-press-single-js": () => import("./../../../src/templates/press-single.js" /* webpackChunkName: "component---src-templates-press-single-js" */),
  "component---src-templates-work-single-js": () => import("./../../../src/templates/work-single.js" /* webpackChunkName: "component---src-templates-work-single-js" */)
}

